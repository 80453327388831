@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Josefin+Sans&text=KaoruWada:300");
@import url("https://fonts.googleapis.com/css?family=Work+Sans:100,200,300,500");
* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  height: 100%;
  min-height: 100%;
  position: relative;
}

body {
  background-color: rgb(251, 253, 252);
  display: flex;
  flex-direction: column;
  font-family: "Work Sans", helvetica, sans-serif;
  font-weight: 300;
  height: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.site-header,
.site-footer {
  flex: none;
}

.site-container {
  background-color: rgb(251, 253, 252);
  flex: 1 0 auto;
  font-family: "Work Sans", helvetica, sans-serif;
  font-weight: 300;
  padding-top: 50px;
  position: relative;
  width: 100%;
}

@media (max-width: 991px) {
  .site-container {
    padding-top: 50px;
  }
}
.site-container::after {
  content: " ";
  display: block;
  height: 0;
  visibility: hidden;
}

a,
a:link,
a:visited {
  border-bottom: 1px solid rgb(60, 60, 60);
  color: rgb(60, 60, 60);
  text-decoration: none;
}

a:active,
a:hover,
a:focus {
  border-bottom: 1px solid #f00;
  color: #f00;
  text-decoration: none;
}

h2 {
  font-size: 1.3em;
  letter-spacing: 2px;
  margin-bottom: 20px;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
}

p {
  font-size: 1.4em;
  margin-bottom: 1.6em;
}

.site-header {
  background-color: rgb(30, 30, 30);
  background-image: url("../img/home/home_banner.jpg");
  background-position: 10% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  position: relative;
}
.site-header .title-container {
  background-color: rgba(255, 255, 255, 0.98);
  display: inline-block;
  padding: 20px;
  position: absolute;
  right: 5%;
  top: 120px;
  z-index: 3;
  width: 50%;
}
.site-header .title-container a:link,
.site-header .title-container a:visited,
.site-header .title-container a:active,
.site-header .title-container a:hover,
.site-header .title-container a:focus {
  color: #000;
  text-decoration: none;
}
.site-header h2 {
  color: #000;
  font-family: "Work Sans", helvetica, sans-serif;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.5em;
  padding-top: 0;
  text-transform: lowercase;
}

h1 {
  color: #000;
  font-family: "Josefin Sans", sans-serif;
  line-height: 1em;
}

a.title-container-link {
  display: block;
  border-bottom: 0;
}

@media (max-width: 980px) {
  .site-header {
    height: 480px;
  }
}
@media (max-width: 700px) {
  .site-header .title-container {
    padding: 5px;
    top: 63px;
  }
  .site-header .title-container h2 {
    display: none;
  }
}
@media (max-width: 600px) {
  .site-header {
    height: 400px;
  }
}
.nav {
  background-color: none;
  bottom: -1px;
  height: 47px;
  position: absolute;
  width: 100%;
  z-index: 4;
}
.nav a,
.nav a:active,
.nav a:focus,
.nav a:hover,
.nav a:link,
.nav a:visited {
  border-bottom: 0;
}
.nav a.mini-logo-container-link {
  display: block;
  width: 150px;
}
.nav h1 {
  font-size: 26px;
  left: 0;
  line-height: 70px;
  padding: 0 0 0 20px;
  position: fixed;
  top: 0;
  transform: translateX(-300px);
  transition: none;
  z-index: 4;
}

@media (max-width: 768px) {
  .site-header .nav h1 {
    transition: none;
  }
}
.nav-container {
  margin: 0;
  position: relative;
  z-index: 3;
}

ul.navigation {
  display: flex;
  transition: padding-left 0.2s ease-in, padding-right 0.2s ease-in;
  width: 100%;
  list-style-type: none;
}
ul.navigation li {
  flex: 1;
  display: block;
  line-height: 47px;
  margin: -1px 0 0;
  text-align: center;
}
ul.navigation li a,
ul.navigation li a:link,
ul.navigation li a:visited {
  background-color: rgba(60, 60, 60, 0.55);
  color: #fff;
  display: block;
  font-size: 1.1em;
  font-weight: 300;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
}
ul.navigation li a:active,
ul.navigation li a:hover,
ul.navigation li a:focus,
ul.navigation li a.this-page,
ul.navigation li a.this-page:link,
ul.navigation li a.this-page:visited {
  background-color: #fbfdfc;
  color: rgb(11, 31, 11);
}

.background-bar {
  background-color: #fff;
  border: 0;
  display: block;
  height: 70px;
  left: 0;
  position: fixed;
  top: 0;
  transform: translateY(-70px);
  transition: none;
  width: 100%;
  z-index: 2;
}

.fixed {
  background-color: #fff;
  height: 70px;
  position: fixed;
  top: 0;
}
.fixed .background-bar {
  transform: translateY(0);
}
.fixed h1 {
  color: rgb(60, 60, 60);
  margin-top: 0;
  transform: translateX(0);
  transition: transform 0.25s linear;
}
.fixed ul.navigation {
  float: right;
  padding-right: 5%;
  width: auto;
}
.fixed ul.navigation li {
  line-height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}
.fixed ul.navigation a,
.fixed ul.navigation a:link,
.fixed ul.navigation a:visited {
  background-color: #fff;
  color: rgb(90, 90, 90);
  transition: background-color 0s;
}
.fixed ul.navigation a:active,
.fixed ul.navigation a:focus,
.fixed ul.navigation a:hover {
  background-color: #fff;
  color: #000;
}

.hamburger {
  display: none;
}

.for-off-canvas {
  display: none;
}

@media (max-width: 800px) {
  .site-header .nav .nav-container,
  .site-header .nav .navigation,
  .site-header .nav.fixed .navigation {
    display: none;
  }
  .site-header {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .hamburger {
    background: rgba(255, 255, 255, 0.98);
    cursor: pointer;
    display: inline;
    height: 32px;
    margin-top: 20px;
    position: fixed;
    right: 5%;
    top: 0;
    width: 42px;
    z-index: 100;
  }
  .hamburger::after,
  .hamburger::before {
    backface-visibility: hidden;
    background: rgb(30, 30, 30);
    content: "";
    height: 2px;
    left: 6px;
    position: absolute;
    transition: 0.75s;
    width: 30px;
  }
  .hamburger::before {
    box-shadow: rgb(30, 30, 30) 0 7px 0 0;
    top: 8px;
  }
  .hamburger::after {
    top: calc(100% - 10px);
  }
  .hamburger.close::before {
    background: rgb(30, 30, 30);
    box-shadow: transparent 0 0 0 0;
    top: 50%;
    transform: rotate(225deg);
  }
  .hamburger.close::after {
    background: rgb(30, 30, 30);
    top: 50%;
    transform: rotate(315deg);
  }
  .hamburger.close,
  .hamburger.close:visited {
    cursor: pointer;
    opacity: 1;
  }
  .hamburger.close:active,
  .hamburger.close:focus,
  .hamburger.close:hover {
    cursor: pointer;
    opacity: 1;
  }
  .hamburger-fixed {
    margin-top: 20px;
  }
  .for-off-canvas {
    background: rgba(255, 255, 255, 0.97);
    display: flex;
    height: 100%;
    overflow: auto;
    position: fixed;
    top: -1600px;
    transition: top 0.5s;
    width: 100%;
    z-index: 98;
  }
  .for-off-canvas.open {
    top: 0;
  }
  .for-off-canvas ul.navigation {
    display: block;
    margin: auto;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .for-off-canvas ul.navigation li {
    font-size: 2.3em;
    margin: 0 0 0.6em;
    padding: 0.3em 0;
    width: 100%;
  }
  .for-off-canvas ul.navigation li a,
  .for-off-canvas ul.navigation li a:link,
  .for-off-canvas ul.navigation li a:visited {
    background-color: rgba(255, 255, 255, 0);
    border: 0;
    color: rgb(40, 40, 40);
    font-size: 1.4em;
  }
  .for-off-canvas ul.navigation li a:active,
  .for-off-canvas ul.navigation li a:focus,
  .for-off-canvas ul.navigation li a:hover {
    background-color: rgba(255, 255, 255, 0);
    border: 0;
    color: #000;
  }
}
@media (max-width: 800px) and (max-height: 450px) {
  .for-off-canvas ul.navigation li {
    font-size: 1.9em;
  }
}
@media (max-width: 800px) and (max-height: 330px) {
  .for-off-canvas ul.navigation li {
    font-size: 1.7em;
  }
}
@media (max-width: 800px) and (max-height: 250px) {
  .for-off-canvas ul.navigation li {
    font-size: 1.5em;
  }
}
@media (max-width: 550px) {
  .for-off-canvas ul.navigation li {
    font-size: 1.9em;
  }
}
.site-footer {
  border-top: 1px solid rgb(200, 210, 200);
  margin-top: 30px;
  padding-bottom: 20px;
  padding-top: 15px;
  text-align: center;
}
.site-footer ul {
  margin-bottom: 0;
}
.site-footer li {
  display: inline;
}
.site-footer li p {
  display: inline;
  font-size: 1em;
}
.site-footer li::after {
  content: "|";
  display: inline;
  font-size: 15px;
  margin-left: 6px;
  margin-right: 2px;
}
.site-footer li:last-child::after {
  content: "";
}

.inside-content-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  margin-bottom: 30px;
}

@media (max-width: 900px) {
  .inside-content-container {
    padding-left: 5%;
    padding-right: 5%;
  }
}
.richtext-image {
  height: auto;
  margin-bottom: 2%;
  margin-top: 0;
  max-width: 48%;
}
.richtext-image.right {
  float: right;
  margin-left: 2%;
  margin-right: 0;
}
.richtext-image.left {
  float: left;
  margin-left: 0;
  margin-right: 2%;
}
.richtext-image.full-width {
  height: auto;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: none;
}

@media (max-width: 786px) {
  .richtext-image.left,
  .richtext-image.right {
    display: block;
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
}
.block-image {
  height: 100%;
  margin-bottom: 20px;
  max-height: 450px;
  overflow: hidden;
  width: 100%;
}
.block-image img {
  height: auto;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.listen {
  text-align: center;
  margin-bottom: 30px;
}
.listen p {
  margin-bottom: 0.1em;
  font-size: 1.1em;
}
.listen iframe {
  margin-top: 40px;
  margin-bottom: 5px;
}
.listen iframe[src*="youtube.com"] {
  aspect-ratio: 16/9;
  width: 100%;
  height: auto;
}