// site wide
$page-background-colour: rgb(251, 253, 252);
$link-colour: rgb(60, 60, 60);
$link-hover-colour: #f00;

$transparent: rgba(255, 255, 255, 0);

// header
$header-background-colour: rgb(30, 30, 30);
$title-box-background-colour: rgba(255, 255, 255, .98);
$title-text-colour: #000;

// footer
$footer-border-colour: rgb(200, 210, 200);

// nav
$wide-screens-nav-item-background-colour: rgba(60, 60, 60, .55);
$wide-screens-nav-item-text-colour: #fff;
$wide-screens-nav-item-hover-background-colour: #fbfdfc;
$wide-screens-nav-item-hover-text-colour: rgb(11, 31, 11);

$nav-item-text-colour: rgb(90, 90, 90);
$nav-item-hover-text-colour: #000;

$navbar-background-colour: #fff;
$navbar-title-text-colour: rgb(60, 60, 60);

// off canvas menu
$off-canvas-menu-background-colour: rgba(255, 255, 255, .97);

$hamburger-background-colour: rgba(255, 255, 255, .98);
$hamburger-colour: rgb(30, 30, 30);

$off-canvas-link-colour: rgb(40, 40, 40);
$off-canvas-link-hover-colour: #000;

// home page
$mini-biog-text-colour: rgb(60, 66, 62);
$press-quote-text-colour: rgb(70, 75, 70);

$press-quote-speechmark-colour: rgb(70, 70, 70);
$press-quote-speechmark-shadow-colour: rgb(160, 160, 160);
$press-quote-attribution-text-colour: #000;

// contact page
$contact-item-text-colour: rgb(160, 170, 165);
$form-error-text-colour: #f00;

$input-field-border-colour: rgb(200, 200, 200);
$input-field-focus-border-colour: rgba(33, 33, 33, 1);
$input-field-text-colour: #000;

$submit-button-text-colour: #000;
$submit-button-background-colour: rgba(255, 255, 255, 1);
$submit-button-border-colour: rgb(40, 40, 40);

$submit-button-hover-text-colour: #fff;
$submit-button-hover-background-colour: rgb(40, 40, 40);

// diary page
$future-past-selector-text-colour: rgb(200, 200, 200);
$future-past-selector-selected-text-colour: rgb(40, 60, 50);

$event-divider-colour: rgb(220, 225, 221);

// media page
$media-h2-text-colour: rgb(40, 60, 50);
