// nav
.nav {
  background-color: none;
  bottom: -1px;
  height: 47px;
  position: absolute;
  width: 100%;
  z-index: 4;

  a,
  a:active,
  a:focus,
  a:hover,
  a:link,
  a:visited {
    border-bottom: 0; // overrides red bottom border on links in site.scss
  }

  a.mini-logo-container-link {
    display: block; // required for fitty.js to work
    width: 150px; // now it's a block element, might as well set the width here
  }

  h1 {
    font-size: 26px;
    left: 0;
    line-height: 70px;
    padding: 0 0 0 20px;
    position: fixed;
    top: 0;
    transform: translateX(-300px);
    transition: none; // so dont see text moving left off screen
    z-index: 4;
  }
}

// remove transition animation on mobile sizes as it looks weird (on iOS as the
// background colour doesn't changer until you release your finger from scrolling)
@media (max-width: 768px) {
  .site-header .nav h1 {
    transition: none;
  }
}

.nav-container {
  margin: 0;
  position: relative;
  z-index: 3;
}

ul.navigation {
  display: flex;
  transition: padding-left .2s ease-in,
              padding-right .2s ease-in;
  width: 100%;
  list-style-type: none;
  
  li {
    flex: 1;
    display: block;
    line-height: 47px;
    margin: -1px 0 0;
    text-align: center;

    a,
    a:link,
    a:visited {
      background-color: $wide-screens-nav-item-background-colour;
      color: $wide-screens-nav-item-text-colour;
      display: block;
      font-size: 1.1em;
      font-weight: 300;
      text-transform: uppercase;
      transition: background-color .2s ease-in,
                  color .2s ease-in;
    }

    a:active,
    a:hover,
    a:focus,
    a.this-page,
    a.this-page:link,
    a.this-page:visited {
      background-color: $wide-screens-nav-item-hover-background-colour;
      color: $wide-screens-nav-item-hover-text-colour;
    }
  }
}

.background-bar {
  background-color: $navbar-background-colour;
  border: 0;
  display: block;
  height: 70px;
  left: 0;
  position: fixed;
  top: 0;
  transform: translateY(-70px);
  transition: none;
  width: 100%;
  z-index: 2;
}

// fixed nav
.fixed {
  background-color: $navbar-background-colour;
  height: 70px;
  position: fixed;
  top: 0;

  .background-bar {
    transform: translateY(0);
  }

  h1 {
    color: $navbar-title-text-colour;
    margin-top: 0;
    transform: translateX(0);
    transition: transform .25s linear;
  }

  ul.navigation {
    float: right; // causes display: block
    padding-right: 5%;
    width: auto;

    li {
      line-height: 50px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
    }

    a,
    a:link,
    a:visited {
      background-color: $navbar-background-colour;
      color: $nav-item-text-colour;
      transition: background-color 0s;
    }

    a:active,
    a:focus,
    a:hover {
      background-color: $navbar-background-colour;
      color: $nav-item-hover-text-colour;
    }
  }
}
