// site footer
.site-footer {
  border-top: 1px solid $footer-border-colour;
  margin-top: 30px;
  padding-bottom: 20px;
  padding-top: 15px;
  text-align: center;

  ul {
    margin-bottom: 0; // to override bootstrap's default 10px
  }

  li {
    display: inline;

    p {
      display: inline;
      font-size: 1em;
    }

    &::after {
      content: '|';
      display: inline;
      font-size: 15px;
      margin-left: 6px;
      margin-right: 2px;
    }

    &:last-child::after {
      content: '';
    }
  }

}
