// offscreen menu
// Hide by default
.hamburger {
  display: none;
}

.for-off-canvas {
  display: none;
}

// Show!
@media (max-width: 800px) {
  .site-header .nav .nav-container,
  .site-header .nav .navigation,
  .site-header .nav.fixed .navigation {
    display: none;
  }

  .site-header {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .hamburger {
    background: $hamburger-background-colour;
    cursor: pointer;
    display: inline;
    height: 32px;
    margin-top: 20px;
    position: fixed;
    right: 5%;
    top: 0;
    width: 42px;
    z-index: 100;
  }

  .hamburger::after,
  .hamburger::before {
    backface-visibility: hidden;
    background: $hamburger-colour;
    content: '';
    height: 2px;
    left: 6px; // left position for all 3 burger bars
    position: absolute;
    transition: .75s;
    width: 30px;
  }

  .hamburger::before {
    box-shadow: $hamburger-colour 0 7px 0 0;
    top: 8px; // top burger bar vertical position
  }

  .hamburger::after {
    top: calc(100% - 10px); // bottom burger bar vertical position
  }

  .hamburger.close::before {
    background: $hamburger-colour;
    box-shadow: transparent 0 0 0 0;
    top: 50%;
    transform: rotate(225deg);
  }

  .hamburger.close::after {
    background: $hamburger-colour;
    top: 50%;
    transform: rotate(315deg);
  }

  .hamburger.close,
  .hamburger.close:visited {
    cursor: pointer;
    opacity: 1;
  }

  .hamburger.close:active,
  .hamburger.close:focus,
  .hamburger.close:hover {
    cursor: pointer;
    opacity: 1;
  }

  .hamburger-fixed {
    margin-top: 20px;
  }

  .for-off-canvas {
    background: $off-canvas-menu-background-colour;
    display: flex;
    height: 100%;
    overflow: auto; // so can scroll if navigation taller than screen size
    position: fixed;
    top: -1600px;
    transition: top .5s;
    width: 100%;
    z-index: 98;

    &.open { // show the offscreen nav
      top: 0;
    }

    ul.navigation {
      display: block;
      margin: auto; // so it can still scroll when larger than screen size
      padding-bottom: 30px;
      padding-top: 30px;
    

      li {
        font-size: 2.3em;
        margin: 0 0 .6em;
        padding: .3em 0;
        width: 100%;

        a,
        a:link,
        a:visited {
          background-color: $transparent;
          border: 0;
          color: $off-canvas-link-colour;
          font-size: 1.4em;
        }

        a:active,
        a:focus,
        a:hover {
          background-color: $transparent;
          border: 0;
          color: $off-canvas-link-hover-colour;
        }
      }
    }
  }
}

@media (max-width: 800px) and (max-height: 450px) {
  .for-off-canvas ul.navigation li {
    font-size: 1.9em;
  }
}

@media (max-width: 800px) and (max-height: 330px) {
  .for-off-canvas ul.navigation li {
    font-size: 1.7em;
  }
}

@media (max-width: 800px) and (max-height: 250px) {
  .for-off-canvas ul.navigation li {
    font-size: 1.5em;
  }
}

@media (max-width: 550px) {
  .for-off-canvas ul.navigation li {
    font-size: 1.9em;
  }
}
