// Site-wide styles for kaoruwada.com
// Jack Lawrence-Jones Nov 2022

@import 'fonts';
@import 'colours';

//  page structure
* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  height: 100%;
  min-height: 100%;
  position: relative;
}

// Page structure (flexbox)
// 1. Avoid the IE 10-11 `min-height` bug.
// 2. Set `flex-shrink` to `0` to prevent some browsers from
//    letting these items shrink to smaller than their content's default
//    minimum size. See http://bit.ly/1Mn35US for details.
// 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.

body {
  background-color: $page-background-colour;
  display: flex;
  flex-direction: column;
  font-family: $font-stack;
  font-weight: 300;
  height: 100%; // 1, 3
  // width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.site-header,
.site-footer {
  flex: none; // 2
}

.site-container {
  background-color: $page-background-colour;
  flex: 1 0 auto; // 2
  font-family: $font-stack;
  font-weight: 300;
  padding-top: 50px;
  position: relative;
  width: 100%;
}

@media (max-width: 991px) {
  .site-container {
    padding-top: 50px;
  }
}

.site-container::after {
  content: '\00a0'; // non-breaking space
  display: block;
  height: 0;
  visibility: hidden;
}

a,
a:link,
a:visited {
  border-bottom: 1px solid $link-colour;
  color: $link-colour;
  text-decoration: none;

}

a:active,
a:hover,
a:focus {
  border-bottom: 1px solid $link-hover-colour;
  color: $link-hover-colour;
  text-decoration: none;
}

h2 {
  font-size: 1.3em;
  letter-spacing: 2px;
  margin-bottom: 20px;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
}

p {
  font-size: 1.4em;
  margin-bottom: 1.6em;
}

@import 'header';
@import 'nav';
@import 'offscreen_menu';
@import 'footer';

// content pages general style
.inside-content-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  margin-bottom: 30px;
}

@media (max-width: 900px) {
  .inside-content-container {
    padding-left: 5%;
    padding-right: 5%;
  }
}

// general
.richtext-image {
  height: auto;
  margin-bottom: 2%;
  margin-top: 0;
  max-width: 48%;

  &.right {
    float: right;
    margin-left: 2%;
    margin-right: 0;
  }

  &.left {
    float: left;
    margin-left: 0;
    margin-right: 2%;
  }

  &.full-width {
    height: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 786px) {
  .richtext-image.left,
  .richtext-image.right {
    display: block;
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
}

.block-image {
  height: 100%;
  margin-bottom: 20px;
  max-height: 450px;
  overflow: hidden;
  width: 100%;

  img {
    height: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

// media page
.listen {
  text-align: center;
  margin-bottom: 30px;

  // small p text for video details
  p {
    margin-bottom: 0.1em;
    font-size: 1.1em;
  }

  iframe {
    // so info text can be put below the video, and it is nearer the video above
    margin-top: 40px;
    margin-bottom: 5px;
  }

  iframe[src*="youtube.com"] {
    aspect-ratio: 16/9;
    width: 100%;
    height: auto; // override html attribute
  }
}
