// site header
.site-header {
  background-color: $header-background-colour;
  background-image: url('../img/home/home_banner.jpg');
  background-position: 10% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  position: relative;

  .title-container {
    background-color: $title-box-background-colour;
    display: inline-block;
    padding: 20px;
    position: absolute;
    right: 5%;
    top: 120px;
    z-index: 3;
    width: 50%;

    a:link,
    a:visited,
    a:active,
    a:hover,
    a:focus {
      color: $title-text-colour;
      text-decoration: none;
    }
  }

  h2 {
    color: $title-text-colour;
    font-family: $font-stack;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: .5em;
    padding-top: 0;
    text-transform: lowercase;
  }
}

h1 { // outside of .site-header scope, so that it styles the mini logo h1 in
     // the left side of the navbar too.
  color: $title-text-colour;
  font-family: $title-font-stack;
  line-height: 1em;
}

a.title-container-link {
  display: block; // required for fitty.js to work
  border-bottom: 0; // override the site wide custom link underline using bottom-border
}

@media (max-width: 980px) {
  .site-header {
    height: 480px;
  }
}

@media (max-width: 700px) {
  .site-header {
    .title-container {
      padding: 5px;
      top: 63px;

      h2 {
        display: none;
      }
    }
  }
}

@media (max-width: 600px) {
  .site-header {
    height: 400px;
  }
}
